<template></template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        startsAt: { type: String, default: dayjs().hour(15).minute(0).millisecond(0).toISOString() },
        endsAt: { type: String, default: dayjs().hour(18).minute(0).millisecond(0).toISOString() },
    },
    data: () => ({
        disabled: null,
        interval: null,
    }),
    mounted() {
        this.interval = setInterval(this.isOn, 500);
    },
    destroyed() {
        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    },
    watch: {
        disabled() {
            this.$emit("change", this.disabled);
        },
    },
    methods: {
        isOn() {
            const now = dayjs();
            const disabled = now.isAfter(this.startsAt, "milliseconds") && now.isBefore(this.endsAt, "milliseconds");
            if (disabled != this.disabled) this.disabled = disabled;
        },
    },
};
</script>

<style></style>
