<template>
    <div class="font-size-12 py-20">
        <v-container>
            <v-btn text color="grey lighten-1" class="v-btn--none-active-bg min-w-auto h-auto pa-0" @click="shows = !shows">
                <span class="font-size-12">SHINJIN (신진유통)</span>
                <v-icon size="18">{{ shows ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
            <v-expand-transition>
                <div v-show="shows" class="grey--text text--lighten-1 pt-6">
                    <div>주소 : 경기도 안양시 동안구 호계3동 엘에스로 37 금명빌딩 7층</div>
                    <div>전화 : 031)425-6422</div>
                    <div>이메일 : shin56355@naver.com</div>
                    <div>사업자등록번호 : 410-81-56355</div>
                    <div>통신판매업신고증 : 제 2007-전남영광-26 호</div>
                </div>
            </v-expand-transition>
        </v-container>
    </div>
</template>

<script>
export default {
    data: () => ({
        shows: false,
    }),
};
</script>

<style>
</style>