<template>
    <client-page>
        <home-navigation />
        <home-notifications />
        <home-footer />
    </client-page>
</template>

<script>
import { mapState } from "vuex";

import ClientPage from "../templates/ClientPage.vue";
import HomeNavigation from "@/components/client/home/home-navigation.vue";
import HomeNotifications from "@/components/client/home/home-notifications.vue";
import HomeFooter from "@/components/client/home/home-footer.vue";

export default {
    components: {
        ClientPage,
        HomeNavigation,
        HomeNotifications,
        HomeFooter,
    },
    computed: {
        ...mapState(["accessToken"]),
    },
    mounted() {
        this.reroute();
    },
    watch: {
        accessToken() {
            this.reroute();
        },
    },
    methods: {
        reroute() {
            if (this.accessToken) {
                if (this.$route.path != "/home") this.$router.replace("/home");
            } else this.$router.replace("/login");
        },
    },
};
</script>
