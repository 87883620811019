var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('div', {
    staticClass: "mb-20"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("공지사항")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-0 h-auto v-btn--none-active-bg",
    attrs: {
      "small": "",
      "text": "",
      "color": "grey",
      "to": "/center/notice"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-plus")]), _vm._v("더 보기")], 1)], 1)], 1)], 1), _c('div', [_c('border-list', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.fixed, function (notification) {
    return _c('border-list-item', {
      key: notification._id,
      attrs: {
        "to": "/center/notice/".concat(notification._id)
      }
    }, [_c('div', {
      staticClass: "d-flex align-center justify-space-between"
    }, [_c('p', {
      staticClass: "page-text text-truncate"
    }, [_vm._v(_vm._s(notification.subject))]), _c('span', {
      staticClass: "page-text text-no-wrap pl-8"
    }, [_vm._v(_vm._s(notification.createdAt.toDate()))])])]);
  }), _vm._l(_vm.notifications, function (notification) {
    return _c('border-list-item', {
      key: notification._id,
      attrs: {
        "to": "/center/notice/".concat(notification._id)
      }
    }, [_c('div', {
      staticClass: "d-flex align-center justify-space-between"
    }, [_c('p', {
      staticClass: "page-text text-truncate"
    }, [_vm._v(_vm._s(notification.subject))]), _c('span', {
      staticClass: "page-text text-no-wrap pl-8"
    }, [_vm._v(_vm._s(notification.createdAt.toDate()))])])]);
  })], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }