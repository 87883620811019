export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const attrs_switch = {
    inset: true,
    "hide-details": true,
    class: "pa-0 ma-0 ml-1 mr-n4",
};
