var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-size-12 py-20"
  }, [_c('v-container', [_c('v-btn', {
    staticClass: "v-btn--none-active-bg min-w-auto h-auto pa-0",
    attrs: {
      "text": "",
      "color": "grey lighten-1"
    },
    on: {
      "click": function click($event) {
        _vm.shows = !_vm.shows;
      }
    }
  }, [_c('span', {
    staticClass: "font-size-12"
  }, [_vm._v("SHINJIN (신진유통)")]), _c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v(_vm._s(_vm.shows ? "mdi-chevron-up" : "mdi-chevron-down"))])], 1), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }],
    staticClass: "grey--text text--lighten-1 pt-6"
  }, [_c('div', [_vm._v("주소 : 경기도 안양시 동안구 호계3동 엘에스로 37 금명빌딩 7층")]), _c('div', [_vm._v("전화 : 031)425-6422")]), _c('div', [_vm._v("이메일 : shin56355@naver.com")]), _c('div', [_vm._v("사업자등록번호 : 410-81-56355")]), _c('div', [_vm._v("통신판매업신고증 : 제 2007-전남영광-26 호")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }