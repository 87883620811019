<template>
    <page-section class="page-section--first">
        <!-- 발주 제한 설정시 -->
        <div v-if="isOrderDisabled" class="font-size-12 secondary--text mb-8">※ 지금은 발주가 불가능한 기간입니다.</div>
        <!-- // 발주 제한 설정시 -->
        <v-row>
            <v-col cols="6" v-for="{ text, to, disabled } in items" :key="text">
                <v-btn block height="64" color="secondary" class="px-22" v-bind="{ to, disabled, loading }">
                    <div class="d-flex align-center justify-space-between w-100">
                        <strong class="font-size-20">{{ text }}</strong>
                        <v-icon size="26" class="mr-n8">mdi-chevron-right</v-icon>
                    </div>
                </v-btn>
            </v-col>
        </v-row>
        <home-navigation-timer @change="(value) => (timerDisabled = value)" />
    </page-section>
</template>

<script>
import { SITE_FEATURES } from "@/assets/variables";

import PageSection from "../sub/page-section.vue";
import HomeNavigationTimer from "./home-navigation-timer.vue";
import { mapState } from "vuex";

let { GIFTSET_ORDER, CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS, BUNDANG_HANDLING_ORDER } = SITE_FEATURES;

export default {
    components: {
        PageSection,
        HomeNavigationTimer,
    },
    data: () => ({
        // timerDisabled: false,
        timerDisabled: null,
    }),
    computed: {
        ...mapState(["isOrderDisabled"]),
        loading() {
            return this.timerDisabled == null;
        },
        scope() {
            return this.$store.state.payload?.scope || [];
        },
        items() {
            let { timerDisabled, isOrderDisabled } = this;
            return [
                {
                    text: "세트발주",
                    to: "/purchase-ordering/giftset-order",
                    test: (scope = []) => scope.some((item) => [GIFTSET_ORDER.value].includes(item)),
                    disabled: isOrderDisabled || timerDisabled,
                },
                {
                    text: "택배접수",
                    to: "/purchase-ordering/customer-order",
                    test: (scope = []) => scope.some((item) => [CUSTOMER_ORDER_BY_UNIT.value, CUSTOMER_ORDER_BY_BULK.value].includes(item)),
                    disabled: isOrderDisabled,
                },
                {
                    text: "특정발주",
                    to: "/purchase-ordering/store-order",
                    test: (scope = []) => scope.some((item) => [STORE_ORDER_BY_COMPANY.value, STORE_ORDER_BY_COURIER.value, STORE_ORDER_BY_EXPRESS.value].includes(item)),
                    disabled: isOrderDisabled || timerDisabled,
                },
                {
                    text: "분당배송",
                    to: "/purchase-ordering/bundang-order",
                    test: (scope = []) => scope.some((item) => [BUNDANG_HANDLING_ORDER.value].includes(item)),
                    disabled: isOrderDisabled,
                },
            ].filter(({ test }) => test(this.scope));
        },
    },
    mounted() {},
};
</script>

<style>
</style>