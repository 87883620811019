<template>
    <page-section>
        <div class="mb-20">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="tit">공지사항</h3>
                </v-col>
                <v-col cols="auto">
                    <v-btn small text color="grey" to="/center/notice" class="pa-0 h-auto v-btn--none-active-bg"><v-icon size="14">mdi-plus</v-icon>더 보기</v-btn>
                </v-col>
            </v-row>
        </div>
        <div>
            <border-list no-gutters>
                <border-list-item v-for="notification in fixed" :key="notification._id" :to="`/center/notice/${notification._id}`">
                    <div class="d-flex align-center justify-space-between">
                        <p class="page-text text-truncate">{{ notification.subject }}</p>
                        <span class="page-text text-no-wrap pl-8">{{ notification.createdAt.toDate() }}</span>
                    </div>
                </border-list-item>
                <border-list-item v-for="notification in notifications" :key="notification._id" :to="`/center/notice/${notification._id}`">
                    <div class="d-flex align-center justify-space-between">
                        <p class="page-text text-truncate">{{ notification.subject }}</p>
                        <span class="page-text text-no-wrap pl-8">{{ notification.createdAt.toDate() }}</span>
                    </div>
                </border-list-item>
            </border-list>
        </div>
    </page-section>
</template>

<script>
import BorderList from "../list/border-list.vue";
import PageSection from "../sub/page-section.vue";
import BorderListItem from "../list/border-list-item.vue";
import api from "@/api";
export default {
    components: {
        BorderList,
        PageSection,
        BorderListItem,
    },
    data: () => ({
        fixed: [],
        notifications: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: { limit: 0 },
                params: { isNotice: true },
            });

            var { notifications } = await api.v1.center.notifications.gets({
                headers: { skip: 0, limit: 10 },
                params: { isNotice: false },
            });

            this.fixed = fixed;
            this.notifications = notifications;
        },
    },
};
</script>

<style>
</style>