var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--first"
  }, [_vm.isOrderDisabled ? _c('div', {
    staticClass: "font-size-12 secondary--text mb-8"
  }, [_vm._v("※ 지금은 발주가 불가능한 기간입니다.")]) : _vm._e(), _c('v-row', _vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
        to = _ref.to,
        disabled = _ref.disabled;
    return _c('v-col', {
      key: text,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-btn', _vm._b({
      staticClass: "px-22",
      attrs: {
        "block": "",
        "height": "64",
        "color": "secondary"
      }
    }, 'v-btn', {
      to: to,
      disabled: disabled,
      loading: _vm.loading
    }, false), [_c('div', {
      staticClass: "d-flex align-center justify-space-between w-100"
    }, [_c('strong', {
      staticClass: "font-size-20"
    }, [_vm._v(_vm._s(text))]), _c('v-icon', {
      staticClass: "mr-n8",
      attrs: {
        "size": "26"
      }
    }, [_vm._v("mdi-chevron-right")])], 1)])], 1);
  }), 1), _c('home-navigation-timer', {
    on: {
      "change": function change(value) {
        return _vm.timerDisabled = value;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }